



























import { defineComponent } from "@vue/composition-api";
import { Button, Cell, CellGroup, Field, Form, Toast, Uploader } from "vant";
import axios from "@/helpers/axios";
import * as qiniu from "qiniu-js";

export default defineComponent({
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  },
  data() {
    return {
      maxFileSize: 60, //mb
      qiniuToken: "",
      videoList: [] as { file: File; status: string; key: string }[],
      toUserPhone: "",
      amount: "" as number | string,
      applyInfo: {}
    };
  },
  created() {
    this.$axios.post("/api/manage/getQiniuToken", {}).then(res => {
      this.qiniuToken = res.data.data;
    });
    this.getApplyInfo();
  },
  methods: {
    async getApplyInfo() {
      const result = await axios
        .post("/api/manage/review/applyInfo", { type: 7, showUserInfo: true })
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!result) return;
      this.applyInfo = result;
    },
    async submit() {
      if (!this.toUserPhone) {
        Toast.fail("请正确填写目标用户手机号");
        return;
      }
      if (Number(this.amount) < 1) {
        Toast.fail("请正确填写金额");
        return;
      }
      if (this.videoList.length != 1 || !this.videoList[0].key) {
        Toast.fail("请上传意愿视频");
        return;
      }
      const result = await axios
        .post("/api/manage/review/applyForAccountBalanceTransfer", {
          toUserPhone: this.toUserPhone,
          amount: Math.floor(Number(this.amount) * 100),
          videoUrl: this.videoList[0].key
        })
        .then(() => true)
        .catch(() => false);
      if (!result) return;
      Toast.success("提交成功");
      this.getApplyInfo();
    },
    onFileOversize() {
      Toast.fail("视频文件大小不能超过" + this.maxFileSize + "MB");
    },
    onPickFile(file: { file: File; status: string; key: string }) {
      file.status = "uploading";
      const observable = qiniu.upload(file.file as File, null, this.qiniuToken);
      observable.subscribe({
        complete: res => {
          file.status = "done";
          file.key = res.key;
        },
        error: () => {
          file.status = "error";
        }
      });
    }
  }
});
